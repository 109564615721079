import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/uireact/uireact/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props } from 'docz';
import * as packageJson from '../package.json';
import Playground from '../../../src/Playground';
import { UiDigitsInput } from '../src';
import { UiIcon } from '@uireact/icons';
import { UiSpacing } from '@uireact/foundation';
import { UiBadge } from '@uireact/badge';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">







    <UiBadge category="warning" mdxType="UiBadge">❗️ Beta</UiBadge>
    <h1 {...{
      "id": "uidigitsinput"
    }}>{`UiDigitsInput`}</h1>
    <sup>
  <a href="https://github.com/inavac182/ui-react/blob/main/packages/form/" target="_blank">
    v{packageJson.version}
  </a>
    </sup>
    <blockquote>
      <p parentName="blockquote">{`Component used to render digits inputs`}</p>
    </blockquote>
    <h2 {...{
      "id": "installation"
    }}>{`Installation`}</h2>
    <blockquote>
      <p parentName="blockquote">{`npm i @uireact/foundation @uireact/form`}</p>
    </blockquote>
    <h2 {...{
      "id": "uidigitsinput-1"
    }}>{`UiDigitsInput`}</h2>
    <Playground __position={2} __code={'<UiDigitsInput\n  label=\"Introduce your code\"\n  digits={6}\n  onChange={value => {\n    console.log(value)\n  }}\n  onComplete={code => {\n    console.log(`completed ${code}`)\n  }}\n/>'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      UiDigitsInput,
      UiIcon,
      UiSpacing,
      UiBadge,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiDigitsInput label="Introduce your code" digits={6} onChange={value => {
        console.log(value);
      }} onComplete={code => {
        console.log(`completed ${code}`);
      }} mdxType="UiDigitsInput" />
    </Playground>
    <h2 {...{
      "id": "uidigitsinput-with-size-and-category"
    }}>{`UiDigitsInput with size and category`}</h2>
    <Playground __position={3} __code={'<UiDigitsInput\n  label=\"Introduce your code\"\n  digits={6}\n  onChange={value => {\n    console.log(value)\n  }}\n  onComplete={code => {\n    console.log(`completed ${code}`)\n  }}\n  size=\"xlarge\"\n  category=\"error\"\n  error=\"Code is not valid\"\n/>'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      UiDigitsInput,
      UiIcon,
      UiSpacing,
      UiBadge,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiDigitsInput label="Introduce your code" digits={6} onChange={value => {
        console.log(value);
      }} onComplete={code => {
        console.log(`completed ${code}`);
      }} size="xlarge" category="error" error="Code is not valid" mdxType="UiDigitsInput" />
    </Playground>
    <h3 {...{
      "id": "props"
    }}>{`Props`}</h3>
    <Props of={UiDigitsInput} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      